































































































































import { Component } from "vue-property-decorator";
import { listMixin } from "@/mixins/listMixin";
import { mixins } from "vue-class-component";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import Pagination from "@/components/paginaiton.vue";
import {
  GetZiliaoList,
  AddZiliao,
  DelZiliao,
  EditZiliao,
} from "@/request/manage";
@Component({
  components: {
    MainHeader,
    NavLeft,
    Pagination,
  },
})
export default class Name extends mixins(listMixin) {
  private window: any = window;
  private data: any[] = [];
  private ifShowChangeCode: boolean = false;
  private preview(item: any) {
    window.open(item.file_url);
  }
  private delOne(item: any) {
    this.$confirm("您是否确定删除？", "删除", {
      customClass: "commonConfirm",
    })
      .then(() => {
        const params: any = {
          data: {
            _id: item._id,
          },
        };
        const loading = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        DelZiliao(this, params)
          .then((data: any) => {
            loading.close();
            this.$message.success("删除成功");
            this.getList();
          })
          .catch(() => {
            loading.close();
          });
      })
      .catch(() => {
        console.log("取消");
      });
  }
  private upload(file: any) {
    const fileObj = file.file;
    // FormData 对象
    const form = new FormData();
    // 文件对象
    form.append("name", fileObj.name);
    form.append("file", fileObj);
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    AddZiliao(this, form, loading)
      .then((res: any) => {
        loading.close();
        this.$message.success("上传成功");
        this.getList();
      })
      .catch(() => {
        loading.close();
      });
  }
  private reUpload(file: any, item: any) {
    const fileObj = file.file;
    // FormData 对象
    const form = new FormData();
    // 文件对象
    form.append("_id", item._id);
    form.append("file", fileObj);
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    EditZiliao(this, form, loading)
      .then((res: any) => {
        loading.close();
        this.$message.success("上传成功");
        this.getList();
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 获取列表
   */
  public getList() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        current_page: this.filters.current_page,
        page_num: this.filters.page_count,
        order_value: this.filters.sort_value,
        order_field: "创建时间",
      },
    };
    GetZiliaoList(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.data = data.data;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }

  /**
   * @description 初始化
   */
  mounted() {
    this.getList();
  }
}
